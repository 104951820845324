@media only screen and (max-device-width: 450px){
    .privacy{
        flex-wrap: wrap;
        justify-content: center;
    }
    .divider{
        display: none;
    }
    .social-networks{
        justify-content: center;
    }
}


.privacy{
    gap: 10px;
    display: flex;
}

.social-networks{
display: flex;
width: 90%;
}

.contact-description{
  position: relative;
  display: block;
  background-color: var(--main-color-1);
  padding: 30px 0 60px 0;
  z-index: 1;
  border-top: 1px solid rgba(255, 255, 255, 0.04);
};