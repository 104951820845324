
html,
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    list-style: none;
    word-wrap: break-word;
}

body {
    font-family: 'Outfit', sans-serif !important;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.75em;
    color: #625c56;
    overflow-x: hidden !important;
    background: var(--main-color-4);
}

p {
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.75em;
    color: #625c56;
    margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Outfit', sans-serif !important;
    font-weight: 800 !important;
    line-height: 1.25em !important;
    margin: 0 0 20px 0 !important;
}

h1 {
    font-size: 60px !important;
}

h2 {
    font-size: 48px !important;
}

h3 {
    font-size: 36px !important;
}

h4 {
    font-size: 27px !important;
}

h5 {
    font-size: 24px !important;
}

h6 {
    font-size: 18px !important;
}

img {
    width: 100%;
    height: auto;
}

img {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    color: #f4f4f4;
}

span,
a,
a:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    outline: none;
}


/* text field */

input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
    outline: none;
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="file"],
textarea {
    max-width: 100%;
    margin-bottom: 15px;
    padding: 15px 40px 15px 15px;
    height: auto;
    background-color: #f4f4f4;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 0 0 1px;
    border-style: solid;
    display: block;
    width: 100%;
    line-height: 1.5em;
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: var(--main-color-1);
    background-image: none;
    border: none;
    border-color: ease-in-out .15s, box-shadow ease-in-out .15s;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 1.75em;
    -webkit-transition: background-color .15s ease-out;
    transition: background-color .15s ease-out;
    background: transparent;
    border: 0px solid transparent;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {
    border: 0px solid transparent;
}

select {
    padding: 10px;
    border-radius: 5px;
}

table,
th,
tr,
td {
    border: 1px solid #f8f5f0;
}

th,
tr,
td {
    padding: 10px;
}

input[type="radio"],
input[type="checkbox"] {
    display: inline;
}

input[type="submit"] {
    font-weight: 300;
    font-family: 'Outfit', sans-serif;
    text-transform: uppercase;
    background: var(--main-color-1);
    color: #f4f4f4;
    padding: 8px 24px;
    margin: 0;
    position: relative;
    font-size: 15px;
    letter-spacing: 2px;
}

.alert-success {
    background: transparent;
    color: #f4f4f4;
    border: 1px solid var(--main-color-2);
    border-radius: 0px;
}


/* placeholder */

::-webkit-input-placeholder {
    color: var(--main-color-1);
    font-size: 15px;
    font-weight: 300;
}

:-moz-placeholder {
    color: var(--main-color-1);
}

::-moz-placeholder {
    color: var(--main-color-1);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--main-color-1);
}


/* blockquote */

blockquote {
    padding: 45px;
    display: block;
    position: relative;
    background-color: #f4f4f4;
    overflow: hidden;
    margin: 35px 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.75em;
    color: var(--main-color-1);
}

blockquote p {
    font-family: inherit;
    margin-bottom: 0 !important;
    color: inherit;
    max-width: 650px;
    width: 100%;
    position: relative;
    z-index: 3;
}

blockquote:before {
    content: '\e645';
    font-family: 'Themify';
    position: absolute;
    right: 40px;
    bottom: 40px;
    font-size: 110px;
    opacity: 0.1;
    line-height: 1;
    color: var(--main-color-2);
}

blockquote p {
    margin-bottom: 0;
}

blockquote p a {
    color: inherit;
}

blockquote cite {
    display: inline-block;
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    position: relative;
    padding-left: 60px;
    border-color: inherit;
    line-height: 1;
    margin-top: 22px;
    font-style: normal;
    color: var(--main-color-1);
    font-weight: 800;
}

blockquote cite:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 45px;
    height: 1px;
    border-top: 1px solid;
    border-color: inherit;
    color: var(--main-color-2);
}


/* ======= Helper style ======= */

:root {
    scroll-behavior: auto;
}

.btn-outline-primary {
  --bs-btn-hover-color: #fff;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  --bs-btn-disabled-bg: #0000;
  --bs-gradient: none;

  --bs-btn-color: var(--main-color-1) !important;
  --bs-btn-border-color: var(--main-color-1) !important;
  --bs-btn-hover-bg: var(--main-color-1) !important;
  --bs-btn-hover-border-color: var(--main-color-1) !important;
  --bs-btn-active-bg: var(--main-color-2) !important;
  --bs-btn-active-border-color: var(--main-color-2) !important;
  --bs-btn-disabled-color: var(--main-color-1) !important;
  --bs-btn-disabled-border-color: var(--main-color-1) !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}


/* important */

.o-hidden {
    overflow: hidden;
}

.position-re {
    position: relative;
}

.full-width {
    width: 100%;
}

.bg-img {
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-fixed {
    background-attachment: fixed;
}

.pattern {
    background-repeat: repeat;
    background-size: auto;
}

.bold {
    font-weight: 400;
}

.count {
    font-family: 'Outfit', sans-serif;
}

.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen  and (ice-width: 767px){
    .v-middle{
        top: 75%;
    }
}

@media only screen  and (min-device-width: 1024px){
    .v-middle{
        top: 50%;
    }
}

.v-middle {
    position: absolute;
    width: 100%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.v-bottom {
    position: absolute;
    width: 100%;
    bottom: 5%;
    left: 0;
    -webkit-transform: translate(0%, -5%);
    transform: translate(0%, -5%);
}

.js .animate-box {
    opacity: 0;
}


/* background & color */

.bg-darkbrown {
    background: var(--main-color-1);
}

.bg-darkbrown p {
    color: #aba59f;
}

.color-1 {
    color: #f4f4f4;
}

.color-2 {
    color: #625c56;
}

.color-3 {
    color: var(--main-color-1);
}


/* star - white  */

.star {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 2em;
    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #f4f4f4;
    border-left: 0.3em solid transparent;
    /* Controlls the size of the stars. */
    font-size: 8px;
}

.star:before,
.star:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #f4f4f4;
    border-left: 1em solid transparent;
    transform: rotate(-35deg);
}

.star:after {
    transform: rotate(35deg);
}


/* Star rating  */

.star-rating {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 2em;
    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #ffd27d;
    border-left: 0.3em solid transparent;
    /* Controlls the size of the stars. */
    font-size: 8px;
}

.star-rating:before,
.star-rating:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #ffd27d;
    border-left: 1em solid transparent;
    transform: rotate(-35deg);
}

.star-rating:after {
    transform: rotate(35deg);
}


/* ======= Selection style ======= */

::-webkit-selection {
    color: #f4f4f4;
    background: var(--main-color-1);
}

::-moz-selection {
    color: #f4f4f4;
    background: var(--main-color-1);
}

::selection {
    color: #f4f4f4;
    background: var(--main-color-1);
}


/* ======= Preloader style ======= */

.preloader-bg,
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--main-color-4);
    z-index: 999999;
}

#preloader {
    display: table;
    table-layout: fixed;
}

#preloader-status {
    display: table-cell;
    vertical-align: middle;
}

.preloader-position {
    position: relative;
    margin: 0 auto;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

.loader {
    position: relative;
    width: 60px;
    height: 60px;
    left: 50%;
    top: auto;
    margin-left: -22px;
    margin-top: 2px;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -ms-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
    border: 2px solid #e9dacb;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.loader span {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -2px;
    left: -2px;
    border: 2px solid transparent;
    border-top: 2px solid var(--main-color-2);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* ======= Owl-Theme custom style ======= */

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0px;
    line-height: 1.5;
    display: block;
    outline: none;
}

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 15px;
    margin-bottom: 15px;
}

.owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 0 3px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid var(--main-color-2);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color-2);
    border: 1px solid var(--main-color-2);
}


/* nav style */

.owl-theme .owl-nav {
    position: absolute !important;
    top: 45% !important;
    bottom: auto !important;
    width: 100%
}

.owl-theme .owl-nav {
    position: relative;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.owl-theme .owl-prev,
.owl-theme .owl-prev {
    left: 10px !important
}

.owl-theme .owl-next {
    right: 10px !important
}

.owl-theme .owl-prev,
.owl-theme .owl-next {
    color: #f4f4f4;
    position: absolute !important;
    top: 50%;
    padding: 0;
    height: 50px;
    width: 50px;
    border-radius: 0%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #272727;
    border: 1px solid #272727;
    line-height: 0;
    text-align: center;
    font-size: 15px
}

.owl-theme .owl-prev > span,
.owl-theme .owl-next > span {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.owl-theme .owl-nav [class*=owl-] {
    width: 50px;
    height: 50px;
    line-height: 45px;
    background: transparent;
    border-radius: 100%;
    color: var(--main-color-2);
    font-size: 10px;
    margin-right: 60px;
    margin-left: 60px;
    cursor: pointer;
    border: 1px solid var(--main-color-2);
    transition: all 0.2s ease-in-out;
    transform: scale(1.0);
}

.owl-theme .owl-nav [class*=owl-]:hover {
    transform: scale(0.9);
    background: transparent;
    border: 1px solid var(--main-color-1);
    color: var(--main-color-1);
}

@media screen and (max-width: 768px) {
    .owl-theme .owl-nav {
        display: none;
    }
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    outline: none;
}


/* ======= Section style ======= */

.section-padding {
    padding: 0px 0;
}

.section-padding2 {
    padding: 0 0 120px 0;
}

.section-padding h6 {
    color: var(--main-color-1);
    margin-bottom: 15px;
}

.section-head {
    position: relative;
    margin: 0 auto 40px;
}

.section-head .section-subtitle {
    color: var(--main-color-2);
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.section-head .section-title {
    font-family: 'Outfit', sans-serif;
    font-size: 48px;
    line-height: 1.2em;
    font-weight: 800;
    color: var(--main-color-1);
}

.section-head .section-title.white {
    color: #f4f4f4;
}

.section-head .section-backtitle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 130px;
    line-height: 150px;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: transparent;
    -webkit-text-stroke: 1px var(--main-color-2);
    opacity: .3;
}

.section-head .icon {
    margin-top: 15px;
    font-size: 15px;
    position: relative;
}

.section-head .icon:after,
.section-head .icon:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: var(--main-color-2);
    top: 50%;
    right: -80px;
}

.section-head .icon.white:after,
.section-head .icon.white:before {
    background: rgba(255, 255, 255, 0.5);
}

.section-head .icon:after {
    right: auto;
    left: -80px;
}

.section-head .icon i {
    color: var(--main-color-2);
    margin: 0 2px;
    font-size: 35px;
}



/* ======= Navbar style ======= */

.navbar-toggler-icon {
  background-image: none;
}
.navbar .navbar-toggler-icon,
.navbar .icon-bar {
  color: #f4f4f4;
}
.navbar .navbar-nav .nav-link {
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #f4f4f4;
  margin: 1px;
  -webkit-transition: all .4s;
  transition: all .4s;
  padding-right: 12px;
  padding-left: 12px;
}
.nav-scroll .navbar-nav .nav-link {
  color: #f4f4f4;
}

.nav-scroll .logo-img {
  width: 110px;
  max-height: 100px;
}
.nav-scroll .logo-img {
  margin-bottom: 0px;
}
.nav-scroll .logo-wrapper .logo h2 {
  font-family: 'Outfit', sans-serif;
  font-size: 27px;
  font-weight: 800;
  display: block;
  width: 100%;
  position: relative;
  color: #f4f4f4;
  margin-bottom: 0;
  text-transform: uppercase;
}
.logo-wrapper {
  float: left;
}
.logo-wrapper .logo h2 {
  font-family: 'Outfit', sans-serif;
  font-size: 27px;
  font-weight: 800;
  display: block;
  width: 100%;
  position: relative;
  color: #f4f4f4;
  margin-bottom: 0;
  text-transform: uppercase;
}

.logo {
  padding: 0;
}
.logo-img {
  width: 120px;
  max-height: 100px;
  margin-bottom: 0px;
}
.dropdown .nav-link i {
  padding-left: 0px;
  font-size: 8px;
  color: rgba(255, 255, 255, 0.5);
}
.navbar .dropdown-menu .dropdown-item {
  padding: 7px 0;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #f4f4f4;
  position: relative;
  -webkit-transition: all .4s;
  transition: all .4s;
  background-color: transparent;
}
.navbar .dropdown-menu .dropdown-item span {
    display: block;
    cursor: pointer;
}
.navbar .dropdown-menu .dropdown-item i {
    padding: 13px 0 0 5px;
    font-size: 8px;
    float: right;
}
.navbar .dropdown-menu .dropdown:hover > .dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {
    color: var(--main-color-2);
}
.navbar .dropdown-toggle::after {
    display: none;
}
.navbar .dropdown-menu li {
   border-bottom: 1px solid rgba(255, 255, 255, 0.02);
}
.navbar .dropdown-menu li:last-child {
   border-bottom: none;
}
.navbar .dropdown-menu .dropdown-menu.pull-left {
    top: 0;
    left: auto;
    right: 100%;
}

/* Navbar Media Query */
@media screen and (min-width: 992px) {
    .navbar .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        width: 200px;
        padding: 15px;
        border-radius: 0;
        border: 0;
        background-color: var(--main-color-1);
        -webkit-transition: all .3s;
        transition: all .3s;
    }
    .navbar .dropdown-menu .dropdown-menu {
        left: calc(100% + 5px);
        top: -10px;
        right: auto;
        min-width: 200px;
        transform: translateY(0);
    }
    .navbar .dropdown:hover > .dropdown-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }    
}
@media screen and (max-width: 991px) {
    .navbar {
        padding-left: 0px;
        padding-right: 0px;
        background: transparent;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .nav-scroll {
        background: var(--main-color-1);
    }
    .logo-wrapper {
        float: left;
        height: 100px;
    }
    .nav-scroll .logo-wrapper {
        background-color: transparent;
        height: 90px;
    }
    .nav-scroll .logo-img {
        width: 110px;
        max-height: 100px;
    }
    .navbar .logo {
        text-align: left;
        margin-left: 0px;
    }
    .nav-scroll .logo {}
    .logo-img {
        margin-bottom: 0px;
        width: 120px;
        max-height: 100px;
    }
    .navbar button {
        margin-right: 0px;
        outline: none !important;
        background: transparent;
        border: none;
        font-size: 25px;
    }
    .navbar button:hover {
        background: transparent;
    }
    .navbar button:active,
    .navbar button:focus,
    .navbar button:hover {
        background: transparent;
        outline: none;
        color: transparent !important;
    }
    .navbar .container {
        max-width: 100%;
        padding: 0;
    }
    .navbar .nav-link {
        margin: 0px auto !important;
    }
    .navbar .navbar-nav .nav-link {
        color: #f4f4f4;
    }
    .nav-scroll .navbar-collapse .nav-link {
        color: #f4f4f4 !important;
    }
    .nav-scroll .navbar-collapse .active {
        color: var(--main-color-2) !important;
    }   
    .navbar .dropdown-menu .dropdown-item {
        padding: 0 15px;
        font-size: 15px;
        line-height: 40px;
    }
    .navbar .dropdown-menu .dropdown-item.dropdown-toggle {
        font-weight: 500;
    }
    .navbar .dropdown-submenu .dropdown-toggle::after {
        right: 15px;
        font-size: 13px;
    }
    .navbar .dropdown-submenu:hover > .dropdown-toggle::after {
        transform: rotate(0deg);
    }
    .navbar .dropdown-submenu .dropdown-toggle.show::after {
        transform: rotate(90deg);
    }
    .navbar .dropdown-menu {
        border: none;
        padding: 0;
        border-radius: 0;
        margin: 0;
        background: var(--main-color-1);
    }
    .navbar .dropdown-submenu .dropdown-menu {
        margin: 0;
        padding: 0 0 0 20px;
    }
    
    .navbar .dropdown-menu li a {
        padding: 0 15px;
    }
    
    .navbar .navbar-nav .nav-link {
        padding-right: 0px;
        padding-left: 0px;
    }

    .navbar .navbar-collapse {
        max-height: 450px;
        overflow: auto;
        background: var(--main-color-1);
        text-align: left;
        padding: 20px;
    }
}



/* ======= Header style ======= */

.header {
    min-height: 100vh;
    overflow: hidden;
}

.header .caption .o-hidden {
    display: inline-block;
}

.header .caption span.icon {
    font-size: 30px;
    margin-bottom: 15px;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

.header .caption .icon {
    margin-bottom: 10px;
    font-size: 20px;
    position: relative;
}

.header .caption .icon i {
    color: #f4f4f4;
    margin: 0 2px;
    font-size: 35px;
}

.header .caption .icon:after,
.header .caption .icon:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: #f4f4f4;
    top: 50%;
    right: -80px;
    opacity: .2;
}

.header .caption .icon:after {
    right: auto;
    left: -80px;
}

.header .caption h6 {
    position: relative;
    margin-bottom: 15px;
    color: #f4f4f4;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.5em;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

.header .caption h6 span {
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

.header .caption h1 {
    position: relative;
    font-size: 60px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f4f4f4;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.header .caption h1 span {
    color: var(--main-color-2);
}

.header .caption h1 i {
    display: block;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-style: normal;
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.header .caption h4 {
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.5em;
    color: #f4f4f4;
    margin-bottom: 0;
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
}

.header .caption p {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 0;
    color: #f4f4f4;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}

.header .caption .button-1 {
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}

.header .caption .button-2 {
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}



/* ======= Slider-fade style (Homepage Slider) ======= */

.slider-fade .owl-item {
    height: 100vh;
    position: relative;
}

.slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: top;
}

.slider-fade .item .caption {
    z-index: 9;
}


/* slider-fade owl-nav next and prev */

.slider-fade .owl-theme .owl-nav {
    position: absolute;
    bottom: 7%;
    right: 12%;
}

.slider-fade .owl-theme .owl-prev {
    float: left;
}

.slider-fade .owl-theme .owl-next {
    float: right;
}

.slider-fade .owl-theme .owl-nav [class*=owl-] {
    width: 50px;
    height: 50px;
    line-height: 43px;
    background: transparent;
    border-radius: 100%;
    color: #f4f4f4;
    font-size: 12px;
    margin-right: 60px;
    margin-left: 60px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.2s ease-in-out;
    transform: scale(1.0);
}

.slider-fade .owl-theme .owl-nav [class*=owl-]:hover {
    transform: scale(0.9);
    background: transparent;
    border: 1px solid var(--main-color-2);
    color: var(--main-color-2);
}

@media screen and (max-width: 768px) {
    .slider-fade .owl-nav {
        display: none;
    }
}


/* owl-dots for slider */

.slider .owl-theme .owl-dots,
.slider-fade .owl-theme .owl-dots {
    position: absolute;
    bottom: 6vh;
    width: 100%;
}

.slider-fade .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0px;
    line-height: .7;
    display: block;
}

.slider-fade .owl-theme .owl-dots .owl-dot span {
    width: 13px;
    height: 13px;
    margin: 0 3px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.slider-fade .owl-theme .owl-dots .owl-dot.active span,
.slider-fade .owl-theme .owl-dots .owl-dot:hover span {
    background: #f4f4f4;
    border: 1px solid #f4f4f4;
}

.slider .owl-theme .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 5%;
    left: 12%;
    text-align: left;
}


/* ======= Slider style (Inner Page Slider) ======= */

.slider .owl-item {
    height: 100vh;
    position: relative;
}

.slider .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.slider .item .caption {
    z-index: 9;
}

.slider .owl-theme .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 3%;
    right: 12%;
    text-align: right;
}

.slider .owl-theme .owl-dots .owl-dot span {
    width: 13px;
    height: 13px;
    margin: 0 3px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.slider .owl-theme .owl-dots .owl-dot.active span,
.slider .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color-2);
    border: 1px solid var(--main-color-2);
}


/* bounce */

.header .arrow {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    z-index: 8;
}

.header .arrow.bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.header .arrow i {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #f4f4f4;
    font-size: 15px;
    border: 1px solid #f4f4f4;
    border-radius: 100%;
}

.header .arrow i:hover {
    border: 1px solid var(--main-color-2);
}

.header .arrow i:hover:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
}



/* ===== Kenburns Slider style ===== */

.kenburns-section {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.kenburns-section::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    background: #000;
}

.kenburns-inner {
    position: relative;
    z-index: 15;
}

.kenburns-inner .caption {
    position: relative;
}

.kenburns-inner .icon {
    margin-bottom: 10px;
    font-size: 20px;
    position: relative;
}

.kenburns-inner .icon i {
    color: #f4f4f4;
    margin: 0 2px;
    font-size: 35px;
}

.kenburns-inner .icon:after,
.kenburns-inner .icon:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: #f4f4f4;
    top: 50%;
    right: -80px;
    opacity: .2;
}

.kenburns-inner .icon:after {
    right: auto;
    left: -80px;
}

.kenburns-inner .caption h6 {
    position: relative;
    margin-bottom: 15px;
    color: #f4f4f4;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.5em;
}

.kenburns-inner .caption h3 {
    position: relative;
    font-family: 'Outfit', sans-serif;
    font-size: 60px;
    line-height: 1em;
    color: #f4f4f4;
    font-weight: 400;
}

.kenburns-inner .caption h3 span {
    display: block;
    font-size: 30px;
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    color: var(--main-color-2);
}

.kenburns-inner .caption h1 {
    position: relative;
    font-size: 60px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f4f4f4;
    line-height: 1.2em;
}

.kenburns-inner .caption h1 span {
    display: block;
    font-size: 36px;
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    letter-spacing: 1px;
    color: transparent;
    -webkit-text-stroke: 1px var(--main-color-2);
    opacity: .8;
}

#kenburnsSliderContainer .vegas-overlay {
    outline: none;
}

@media screen and (max-width: 767px) {
    .kenburns-inner .caption h1 {
        font-size: 36px;
    }

    .kenburns-inner .caption h1 span {
        font-size: 27px;
    }

    .kenburns-inner .caption h3 {
        font-size: 36px;
    }

    .kenburns-inner .caption h3 span {
        font-size: 27px;
    }

    .kenburns-inner .caption {
        text-align: center;
    }
}


/* bounce */

.kenburns-section .arrow {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    z-index: 999;
    outline: none;
}

.kenburns-section .arrow.bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.kenburns-section .arrow i {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #f4f4f4;
    font-size: 15px;
    border: 1px solid #f4f4f4;
    border-radius: 100%;
}

.kenburns-section .arrow i:hover {
    border: 1px solid var(--main-color-2);
    color: var(--main-color-2);
}

.kenburns-section .arrow i:hover:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
}



/* ======= Page Banner Header style ======= */

.banner-header {
    padding-top: 160px;
    padding-bottom: 160px;
    background-position: center;
}

.banner-header.full-height {
  min-height: 100vh;
}

.banner-header .caption {
    position: relative;
}

.banner-header h1 {
    position: relative;
    font-size: 60px;
    color: #f4f4f4;
    letter-spacing: 1px;
}

.banner-header h1 span {
    color: var(--main-color-2);
}

.banner-header h2 {
    position: relative;
    font-size: 48px;
    color: #f4f4f4;
    letter-spacing: 1px;
}

.banner-header h2 span {
    color: var(--main-color-2);
}

.banner-header h3 {
    position: relative;
    font-size: 60px;
    line-height: 1em;
    color: #f4f4f4;
}

.banner-header h3 span {
    display: block;
    font-size: 30px;
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    color: var(--main-color-2);
}

.banner-header h5 {
    position: relative !important;
    margin-bottom: 15px !important;
    color: #f4f4f4 !important;
    font-size: 12px !important;
    letter-spacing: 2px !important;
    line-height: 1.5em !important;
    font-family: 'Outfit', sans-serif !important;
    text-transform: uppercase !important;
}

.banner-header h5 a {
    color: var(--main-color-2);
}

.banner-header p {
    font-size: 15px;
    margin-bottom: 0;
    color: #f4f4f4;
}

.banner-header p a {
    color: var(--main-color-2);
}

.banner-header .icon {
    margin-bottom: 15px;
    font-size: 20px;
    position: relative;
}

.banner-header .icon:after,
.banner-header .icon:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: #f4f4f4;
    top: 50%;
    right: -80px;
    opacity: .2;
}

.banner-header .icon:after {
    right: auto;
    left: -80px;
}

.banner-header .icon i {
    color: #f4f4f4;
    margin: 0 2px;
    font-size: 30px;
}


/* author & date */

.banner-header .author .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
}

.banner-header .post {
    margin-top: 20px;
}

.banner-header .post div {
    display: inline-block;
}

.banner-header .post .author {
    margin-right: 20px;
    font-size: 12px;
}

.banner-header .post .date-comment {
    position: relative;
    font-size: 12px;
    color: #f4f4f4;
    margin-right: 20px
}

.banner-header .post .date-comment i {
    color: #f4f4f4;
    font-size: 18px;
    margin-right: 5px;
}

.banner-header .post .date-comment:last-of-type {
    margin-right: 0px;
}


/* bounce */

.banner-header .arrow {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    z-index: 8;
    outline: none;
}

.banner-header .arrow.bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.banner-header .arrow i {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #f4f4f4;
    font-size: 15px;
    border: 1px solid #f4f4f4;
    border-radius: 100%;
}

.banner-header .arrow i:hover {
    border: 1px solid var(--main-color-2);
    color: var(--main-color-2);
}

.banner-header .arrow i:hover:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
}

@media screen and (max-width: 767px) {
    .banner-header {}

    .banner-header h5 {}

    .banner-header h1 {
        font-size: 36px;
        letter-spacing: 0;
    }

    .banner-header h1 span {
        font-size: 20px;
    }

    .banner-header h3 {
        font-size: 36px;
    }

    .banner-header h3 span {
        font-size: 27px;
    }
}


/* =======  Video Background style  ======= */

.video-fullscreen-wrap {
    height: 100vh;
    overflow: hidden;
}

.video-fullscreen-wrap .caption {
    position: relative;
}

.video-fullscreen-wrap .caption.text-left {
    text-align: left;
}

.video-fullscreen-wrap h3 {
    position: relative;
    font-family: 'Outfit', sans-serif;
    font-size: 60px;
    line-height: 1em;
    color: #f4f4f4;
    font-weight: 400;
}

.video-fullscreen-wrap h3 span {
    display: block;
    font-size: 30px;
    display: block;
    margin-top: 0px;
    line-height: 1.5em;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    color: var(--main-color-2);
}

.video-fullscreen-video {
    height: 100%;
}

.video-fullscreen-wrap video {
    width: 100vw;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.video-fullscreen-wrap .video-fullscreen-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
}

.video-lauch-wrapper {
    border: none;
    background: none;
    width: 100%;
    padding: 0;
}

.video-fullscreen-wrap .overlay {
    /*  background-image: linear-gradient(to bottom right, #000000, #000000);*/
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 4;
}

.video-fullscreen-wrap .butn-dark a {
    color: #f4f4f4;
}

.position-relative {
    position: relative !important;
}


/* =======  About style  ======= */

.about-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px 0;
}

.image.about-signature {
    margin-right: 20px;
    width: 70px;
}

.about-name {
    font-size: 15px;
    color: var(--main-color-2);
    font-family: 'Outfit', sans-serif;
}

.about-rol {
    color: #aba59f;
    font-size: 15px;
}


/* about list */

.about-list {
    position: relative;
    display: block;
}

.about-list li {
    position: relative;
    display: flex;
    align-items: center;
}

.about-list li + li {
    margin-top: 5px;
}

.about-list-icon {
    display: flex;
    align-items: center;
}

.about-list-icon span {
    font-size: 12px;
    color: var(--main-color-2);
}

.about-list-text {
    margin-left: 15px;
}

.about-list-text p {
    font-size: 15px;
    margin: 0;
}


/* ======= Team style ======= */

.team,
.team-page {
    position: relative;
}

.team:before {
    top: auto;
    bottom: 0;
    height: 40%;
    background: var(--main-color-1);
}

.team:after,
.team:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 60%;
    width: 100%;
    background: var(--main-color-1);
    z-index: -1;
}

.team-card,
.team-page-card {
    text-align: center;
    position: relative;
    padding-bottom: 40px
}

.team-card .title-box,
.team-page-card .title-box {
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 0;
    z-index: 2;
    background-color: var(--main-color-4);
    padding: 25px 0;
    opacity: 1;
    visibility: visible;
    margin-bottom: 0;
    border-radius: 1px;
}

.team-card .title-box h3,
.team-page-card .title-box h3 {
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    color: var(--main-color-1);
}

.team-card .title-box h3 span,
.team-page-card .title-box h3 span {
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #625c56;
    display: grid;
}

.team-card .team-img,
.team-page-card .team-img {
    position: relative
}

.team-card .team-img:before,
.team-page-card .team-img:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: var(--main-color-1);
    opacity: 0.9;
    visibility: hidden
}

.team-card .team-content,
.team-page-card .team-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-bottom: 38px;
    margin-top: 50px;
    opacity: 0;
    visibility: hidden
}

.team-card .team-title,
.team-page-card .team-title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    color: #f4f4f4;
}

.team-card .team-title span,
.team-page-card .team-title span {
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    color: var(--main-color-2);
    font-weight: 400;
    display: grid;
}

.team-card .team-text,
.team-page-card .team-text {
    font-size: 15px;
    color: #f4f4f4;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto
}

.team-card .title-box,
.team-card .team-content,
.team-card .team-img:before,
.team-page-card .title-box,
.team-page-card .team-content,
.team-page-card .team-img:before {
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.team-card:hover .team-content,
.team-page-card:hover .team-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    margin-top: 1px
}

.team-card:hover .title-box,
.team-page-card:hover .title-box {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 80px
}

.team-card:hover .team-img::before,
.team-page-card:hover .team-img::before {
    height: 100%;
    visibility: visible
}

.team-card .social,
.team-page-card .social {
    position: relative;
    left: 0;
    width: 100%;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.team-card .social a,
.team-page-card .social a {
    font-size: 15px;
    color: var(--main-color-2);
    margin: 0 5px;
}

.team-card .social a:hover,
.team-page-card .social a:hover {
    color: #f4f4f4;
}

@media (max-width: 1199px) {

    .team-card .title-box,
    .team-page-card .title-box {
        left: 20px;
        right: 20px;
        padding: 25px 0
    }

    .team-card .team-text,
    .team-page-card .team-text {
        max-width: 280px
    }
}


/* team details */

.team-box {
    position: relative;
    margin-bottom: 45px;
}

.team-box .wrap {
    flex-wrap: wrap;
    background: #f4f4f4;
}

.team-box .wrap .desc {
    padding: 45px 40px;
}

.team-box .wrap .desc .section-title {
    font-family: 'Outfit', sans-serif;
    font-size: 27px;
    line-height: 1.2em;
    font-weight: 800;
    color: var(--main-color-1);
}

.team-box .wrap .desc .section-subtitle {
    color: var(--main-color-2);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.team-box .wrap .desc p {
    margin-bottom: 0;
}

.team-box .cont {
    width: 100%;
    background-color: var(--main-color-2);
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: rgba(255, 255, 255, 0.1);
    padding: 22px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.team-box .cont .coll {
    width: auto;
    max-width: auto;
}

.team-box .cont .coll h6 {
    font-size: 15px;
    font-weight: 300;
    line-height: 1em;
    white-space: normal;
    margin: 0;
    color: #f4f4f4;
}

.team-box .cont .coll h5 {
    font-size: 15px;
    font-weight: 400;
    white-space: normal;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
    transition: color .3s;
    color: #f4f4f4;
}


/* team detail social */

.team-box .cont .coll .social-icon {
    text-align: center;
    margin-bottom: 0px;
}

.team-box .cont .coll .social-icon a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #f4f4f4;
    border-radius: 100%;
    margin-right: 3px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.team-box .cont .coll .social-icon a:hover {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    color: var(--main-color-1);
}


/* team detail tabs */

.team-box .simpl-bord.nav-tabs {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(145, 118, 90, 0.3);
}

.team-box .simpl-bord .nav-item {
    margin-right: 50px;
}

.team-box .simpl-bord .nav-item:last-of-type {
    margin-right: 0;
}

.team-box .simpl-bord .nav-link {
    padding: 0;
    border: 0;
    color: inherit;
    background: transparent !important;
    font-weight: 800;
    cursor: pointer;
    color: var(--main-color-1);
    font-size: 18px;
}

.team-box .simpl-bord .nav-link.active {
    font-weight: 800;
    color: var(--main-color-2);
}


/* ======= Barber Services style ======= */

.barber-services .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.barber-services .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.barber-services .item:hover .con {
    bottom: 0;
}

.barber-services .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.barber-services .item .category {
    position: absolute;
    background: var(--main-color-2);
    color: #f4f4f4;
    font-size: 12px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    text-align: center;
    vertical-align: middle;
    transform: rotate(-20deg);
    z-index: 3;
    left: 30px;
    top: 30px;
}

.barber-services .item .category:before,
.barber-services .item .category:after {
    content: "";
    position: absolute;
    background: inherit;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
    z-index: -2;
    transform: rotate(30deg);
}

.barber-services .item .category:after {
    transform: rotate(60deg);
}

.barber-services .item .con {
    padding: 80px;
    position: absolute;
    bottom: -123px;
    text-align: center;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 20;
    height: auto;
    box-sizing: border-box;
    background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .1) 2%, rgba(0, 0, 0, .75) 90%);
    background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
    background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
}

.barber-services .item .con .icon {
    color: #f4f4f4;
    font-size: 40px;
    margin-bottom: 10px;
}

.barber-services .item .con h6,
.barber-services .item .con h6 a {
    position: relative;
    color: var(--main-color-2);
    font-weight: 800;
    font-size: 24px;
    margin-top: 10px;
}

.barber-services .item .con h6 i {
    margin-right: 2px;
}

.barber-services .item .con h5,
.barber-services .item .con h5 a {
    position: relative;
    color: #f4f4f4;
    font-size: 27px;
}

.barber-services .item .con p {
    color: #f4f4f4;
    font-size: 20px;
}

.barber-services .item .con i {
    color: #f4f4f4;
    font-size: 18px;
}

.barber-services .item .con i:hover {
    color: #f4f4f4;
}

.barber-services .item .con .butn-light {
    margin-left: 0;
    border-radius: 30px;
    border-color: var(--main-color-1);
    padding: 11px 30px;
}

.barber-services .item .con .butn-light:hover {
    border-color: var(--main-color-1);
}


/* line animation */

.barber-services .item .line {
    text-align: center;
    height: 1px;
    width: 60px;
    background-color: var(--main-color-2);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: 0 auto 20px;
}

.barber-services .item:hover .line {
    width: 100%;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: ease-in-out;
}


/* permalink */

.barber-services .item .con .permalink {
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    color: #f4f4f4;
    height: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    display: inline;
}

.barber-services .item .con .permalink a {
    color: var(--main-color-2);
    text-align: center;
}

.barber-services .item .con .permalink i {
    font-size: 10px;
    text-align: center;
}

.barber-services .item .con .permalink a:hover,
.barber-services .item .con .permalink i:hover {
    color: #f4f4f4;
}


/* ======= Barber Services 2 style  ======= */

.barber-services-2 {
    position: relative;
}

.barber-services-2:last-child {
    margin-bottom: 15px;
}

.barber-services-2 figure {
    margin: 0;
    position: relative;
    width: 66.666667%;
    margin-left: auto;
}

.barber-services-2.left figure {
    margin-left: 0;
    margin-right: auto;
}

.barber-services-2 figure img:hover {
    transform: scale(0.98);
}

.barber-services-2 .caption {
    background: #f4f4f4;
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    padding: 4% 4% 4% 4%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.barber-services-2.left .caption {
    left: auto;
    right: 0;
    background: #f4f4f4;
    padding: 30px;
}

.barber-services-2 .caption.padding-left {
    padding-left: 4%;
}

.barber-services-2.left .caption.padding-left {
    padding-right: 4%;
}

.barber-services-2 .caption h4,
.barber-services-2 .caption h4 a {
    font-size: 32px;
    color: var(--main-color-1);
    margin-bottom: 15px;
}

.barber-services-2 .caption h3,
.barber-services-2 .caption h3 a {
    font-size: 12px;
    color: var(--main-color-2);
    margin-bottom: 5px;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.barber-services-2 .caption h3 span {
    font-size: 12px;
    color: var(--main-color-1);
    margin-bottom: 5px;
    font-family: 'Outfit', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.barber-services-2 .caption h6 {
    display: inline-block;
    color: var(--main-color-1);
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 10px;
    letter-spacing: 5px;
    text-transform: uppercase;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.barber-services-2 .caption .info-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.barber-services-2 .caption .info-wrapper .icon {
    color: #625c56;
    font-size: 12px;
    font-family: 'Outfit', sans-serif;
    letter-spacing: 0px;
    font-weight: 300;
}

.barber-services-2 .caption .info-wrapper .icon.ti-calendar:before {
    content: "\e6b6";
    font-family: 'Themify';
    margin-right: 5px;
    color: var(--main-color-1);
}

.barber-services-2 .caption .info-wrapper .more {
    color: var(--main-color-1);
    text-decoration: none;
    font-size: 12px;
}

.barber-services-2 .caption .info-wrapper .more i {
    color: var(--main-color-1);
    font-size: 10px;
}

.barber-services-2 .caption .info-wrapper .date {
    color: var(--main-color-1);
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    background: transparent;
    border: 1px solid var(--main-color-1);
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 3px 12px;
}

.barber-services-2 .caption .info-wrapper .date:hover {
    background: transparent;
    border: 1px solid var(--main-color-1);
    color: var(--main-color-1);
}

@media (max-width: 991.98px) {
    .barber-services-2 figure {
        width: auto;
        margin: 0;
    }

    .barber-services-2 .caption {
        position: relative;
        left: 0;
        top: 0;
        width: auto;
        padding: 30px 20px;
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}


/* hr style */

hr {
    margin-top: 5px;
    margin-bottom: 15px;
    border: 0;
}

hr.border-1 {
    border-top: 1px solid rgba(145, 118, 90, 0.001);
}

hr.border-2 {
    border-top: 1px solid rgba(145, 118, 90, 0.001);
    border-width: 1px !important;
    opacity: 0.1;
}


/* ======= First Class Services ======= */

.first-class-services {
    position: relative;
    position: -webkit-sticky;
}

.first-class-services:before {
    top: auto;
    bottom: 0;
    height: 40%;
    background: var(--main-color-4);
}

.first-class-services:after,
.team:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 60%;
    width: 100%;
    background: var(--main-color-1);
    z-index: -1;
}

.first-class-services .square-flip {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -ms-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    background-size: cover;
    background-position: center center;
}

.first-class-services .square-flip {
    min-height: 450px;
    margin-bottom: 30px;
}

.first-class-services .square,
.first-class-services .square2 {
    width: 100%;
    height: 100%;
}

.first-class-services .square {
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.first-class-services .square .category {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    writing-mode: vertical-rl;
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    text-orientation: mixed;
    text-transform: uppercase;
    color: #f4f4f4;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    letter-spacing: 1px;
    background: var(--main-color-2);
    border: 1px solid var(--main-color-2);
    padding: 10px 3px;
    line-height: 1.5em;
}

.first-class-services .square .category a {
    color: #f4f4f4;
}

.first-class-services .square .category:hover {
    color: #f4f4f4;
}

.first-class-services .square h4 {
    color: #f4f4f4;
    font-size: 27px;
    margin-top: 0px;
    margin-bottom: 10px;
    display: inline-flex;
}

.first-class-services .square-container .box-title .icon {
    color: #f4f4f4;
    font-size: 36px;
    margin-bottom: 15px;
}

.first-class-services .square h6,
.first-class-services .square h6 a {
    position: relative;
    color: var(--main-color-2);
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.first-class-services .square-flip .square {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}

.first-class-services .square-flip:hover .square {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.first-class-services .square2 {
    background: var(--main-color-2);
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.first-class-services .square2.bg-white {
    background: #f4f4f4;
}

.first-class-services .square2:before {
    top: 25px;
    left: 15px;
    right: 15px;
    bottom: 25px;
}

.first-class-services .square2 h4 {
    font-size: 27px;
    color: #f4f4f4;
}

.first-class-services .square2 h6 {
    position: relative;
    color: #f4f4f4;
    font-family: 'Outfit', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

.first-class-services .square2 p {
    font-size: 15px;
    color: #f4f4f4;
}

.first-class-services .square2 p i {
    font-style: normal;
}

.first-class-services .square2 .icon img {
    width: 60px;
    color: var(--main-color-1);
    margin-bottom: 15px;
}

.first-class-services .btn-line a {
    border-bottom: 1px solid #ccc;
    color: var(--main-color-1);
}

.first-class-services .btn-line a:hover {
    border-bottom: 1px solid var(--main-color-1);
    color: var(--main-color-1);
}

.first-class-services .square-flip .square2 {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}

.first-class-services .square-flip:hover .square2 {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.first-class-services .square-container {
    padding: 40px 30px;
    text-align: right;
    height: 450px;
    position: relative;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateX(0px) scale(1);
    -ms-transform: translateX(0px) scale(1);
    transform: translateX(0px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}

.first-class-services .square-container .box-title {
    width: 100%;
    text-align: center;
}

.first-class-services .square-flip:hover .square-container {
    -webkit-transform: translateX(-650px) scale(0.88);
    -ms-transform: translateX(-650px) scale(0.88);
    transform: translateX(-650px) scale(0.88);
}

.first-class-services .square-container2 {
    padding: 30px;
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}

.first-class-services .square-container2 .icon {
    font-size: 36px;
    margin-bottom: 10px;
}

.first-class-services .square-flip:hover .square-container2 {
    -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
}

.first-class-services .flip-overlay {
    display: block;
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .3;
}

@media screen and (max-width: 991px) {
    .first-class-services .square-flip {
        margin-bottom: 30px;
    }

    .first-class-services {
        padding-bottom: 70px;
    }
}


/* ======= Services Box style ======= */

.services-box .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
}

.services-box .item .icon {
    color: var(--main-color-2);
    margin-right: 30px;
}

.services-box .item .icon:before {
    font-size: 50px;
    line-height: 50px;
}

.services-box .item h5 {
    margin-bottom: 10px;
    font-size: 24px;
}

.services-box .item p {
    font-size: 15px;
}


/* ======= Services-1 style ======= */

.services-1 .item {
    background: #fdfbf8;
    padding: 40px 20px;
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    line-height: 1;
    text-align: center;
}

.services-1 .item h5 {
    font-size: 24px;
    color: var(--main-color-1);
    margin-bottom: 15px;
}

.services-1 .item:hover h5 {
    color: var(--main-color-2);
    -webkit-transition: .5s;
    transition: .5s;
}

.services-1 .item:hover span {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.services-1 .item span {
    font-size: 60px;
    color: var(--main-color-2);
    line-height: 1;
    margin-bottom: 10px;
    display: inline-block;
    -webkit-transition: .5s;
    transition: .5s;
}

.services-1 .item:hover span {
    color: var(--main-color-1);
}

.services-1 .item p {
    margin: 0;
    color: var(--main-color-1);
}

.services-1 .item .shape {
    position: absolute;
    opacity: 0.03;
    z-index: -1;
    bottom: 15px;
    left: 120px;
}

.services-1 .item .shape span {
    font-size: 140px;
    color: var(--main-color-1);
}


/* hr */

hr {
    margin-top: 5px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
}


/* services sidebar */

.sidebar .sidebar-widget {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.sidebar .sidebar-widget:last-child {
    margin-bottom: 0px;
}

.sidebar .sidebar-widget .widget-inner {
    position: relative;
    display: block;
    padding: 45px;
    background: #f4f4f4;
    border-radius: 0px;
}

.sidebar-title {
    position: relative;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--main-color-4);
    padding-bottom: 15px;
}

.sidebar-title h4 {
    position: relative;
    display: inline-block;
    font-size: 27px;
    color: var(--main-color-1);
    margin-bottom: 0px;
}

.sidebar .services ul {
    position: relative;
    padding-left: 0;
}

.sidebar .services ul li {
    position: relative;
    line-height: 48px;
    color: var(--main-color-1);
    margin-bottom: 5px;
}

.sidebar .services ul li:before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    right: -20px;
    bottom: 0;
    background: var(--main-color-2);
    border-radius: 0;
    opacity: 0;
    transition: all 0.3s ease;
}

.sidebar .services ul li:after {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: -12px;
    height: 24px;
    border-left: 2px solid var(--thm-base);
    opacity: 0;
    transition: all 0.3s ease;
}

.sidebar .services ul li:last-child {
    margin-bottom: 0;
}

.sidebar .services ul li a {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: var(--main-color-1);
    transition: all 0.3s ease;
    z-index: 1;
}

.sidebar .services ul li:hover a,
.sidebar .services ul li.active a {
    color: #f4f4f4;
}

.sidebar .services ul li:hover:before,
.sidebar .services ul li.active:before,
.sidebar .services ul li:hover:after,
.sidebar .services ul li.active:after {
    opacity: 1;
}

.sidebar .services ul li a:after {
    position: absolute;
    right: 0;
    top: 1px;
    font-family: "themify";
    content: "\e649";
    opacity: 1;
    font-size: 10px;
    font-weight: normal;
    z-index: 1;
    font-style: normal;
}

.sidebar .services ul li a:hover:before,
.sidebar .services ul li.active a:before {
    opacity: 1;
    transform: scaleX(1);
    transition: all 0.3s ease 0.1s;
}


/* ======= Clients style ======= */

.clients {
    background: var(--main-color-2);
    padding-top: 20px;
    padding-bottom: 10px;
}

.clients .owl-carousel {
    margin: 0px 0;
}

.clients .clients-logo {
    opacity: 1;
    line-height: 0;
}

.clients .clients-logo:hover {
    opacity: 1;
}


/* ======= Gallery style ======= */

.gallery-item {
    position: relative;
    padding-top: 20px;
}

.gallery-box {
    overflow: hidden;
    position: relative;
}

.gallery-box .gallery-img {
    position: relative;
    overflow: hidden;
}

.gallery-box .gallery-img:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-img > img {
    transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
    border-radius: 0;
}

.gallery-box .gallery-detail {
    opacity: 0;
    color: #f4f4f4;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.gallery-box .gallery-detail h4 {
    font-size: 18px;
}

.gallery-box .gallery-detail p {
    color: Rgba(0, 0, 0, 0.4);
    font-size: 12px;
}

.gallery-box .gallery-detail i {
    color: var(--main-color-1);
    padding: 8px;
}

.gallery-box .gallery-detail i:hover {
    color: var(--main-color-1);
}

.gallery-box:hover .gallery-detail {
    top: 50%;
    transform: translate(0, -50%);
    opacity: 1;
}

.gallery-box:hover .gallery-img:after {
    background: rgba(0, 0, 0, 0.2);
}

.gallery-box:hover .gallery-img > img {
    transform: scale(1.05);
}


/* magnific popup custom */

.mfp-figure button {
    border: 0px solid transparent;
}

button.mfp-close,
button.mfp-arrow {
    border: 0px solid transparent;
    background: transparent;
}


/* ======= Testimonials style ======= */

.testimonials-box {
    position: relative;
    background: transparent;
    padding: 30px;
    margin-bottom: 90px;
}

.testimonials-box .head-box {
    position: relative;
    margin: 0 auto 20px;
}

.testimonials-box .head-box .subtitle {
    color: var(--main-color-2);
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.testimonials-box .head-box .title {
    font-family: 'Outfit', sans-serif;
    font-size: 36px;
    font-weight: 400;
    color: #f4f4f4;
}

.testimonials-box .head-box .title span {
    color: var(--main-color-1);
}

.testimonials-box .head-box .backtitle {
    position: absolute;
    font-family: 'Pinyon Script', cursive;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 130px;
    opacity: .1;
    line-height: 65px;
    z-index: -2;
    color: #f4f4f4;
}

.testimonials h5 {
    color: #f4f4f4;
    font-size: 27px;
    margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
    .testimonials-box {
        margin-bottom: 90px;
        padding: 35px 20px;
    }
}

.testimonials .item {
    margin-bottom: 0px;
    position: relative;
}

.testimonials .item .quote {
    position: absolute;
    right: 20px;
    top: 90px;
    width: 70px;
    margin: 0 auto 30px;
    opacity: .1;
}

.testimonials .item p {
    color: #f4f4f4;
}

.testimonials .item .info {
    margin: 20px 0 0 0;
}

.testimonials .item .info img {
    border-radius: 100%;
}

.testimonials .item .info .author-img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.testimonials .item .info .cont {
    margin-top: 0px;
}

.testimonials .item .info h6 {
    font-size: 12px;
    margin-bottom: 0px;
    padding-top: 10px;
    color: #f4f4f4;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.testimonials .item .info span {
    font-size: 12px;
    color: #f4f4f4;
}

.testimonials .item .info .rate {
    float: right;
}

.testimonials .item .info i {
    color: var(--main-color-1);
    font-size: 6px;
}

@media screen and (max-width: 767px) {
    .testimonials-box {
        padding: 0px;
    }
}


/* ======= Opening Hours style ======= */

.opening-hours-box {
    position: relative;
    background: var(--main-color-1);
    padding: 60px 45px;
    margin-bottom: -75px;
    text-align: center;
}

.opening-hours-box .head-box h6 {
    font-family: 'Outfit', sans-serif;
    color: var(--main-color-2);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.opening-hours-box p {
    color: #aba59f;
    margin-bottom: 0;
    margin-top: 30px;
}

.opening-hours-box p small {
    color: #aba59f;
}

.opening-hours-box p small a {
    color: var(--main-color-2);
    font-size: 15px;
    font-weight: 800;
}

.opening-hours-box p a i {
    color: var(--main-color-2);
}

.opening-hours-box .head-box h4 {
    color: #f4f4f4;
    font-size: 27px;
    margin-bottom: 15px;
    padding-bottom: 20px;
}

.opening-hours-box .head-box h4 span {
    color: #f4f4f4;
}

.opening-hours-box .item .info .cont {
    vertical-align: middle;
    margin-bottom: 15px;
}

.opening-hours-box .item ul {
    margin: 0;
    padding: 0;
}

.opening-hours-box .item ul li .tit,
.opening-hours-box .item ul li .dots,
.opening-hours-box .item ul li span {
    display: table-cell;
    white-space: nowrap;
}

.opening-hours-box .item ul li {
    margin-bottom: 15px;
    font-size: 15px;
}

.opening-hours-box .item ul li:last-of-type {
    margin-bottom: 0;
}

.opening-hours-box .item ul li span {
    white-space: nowrap;
    padding-left: 10px;
    font-family: 'Outfit', sans-serif;
    color: #aba59f;
}

.opening-hours-box .item ul li .tit {
    padding-right: 10px;
    color: #aba59f;
}

.opening-hours-box .item ul li .dots {
    height: 4px;
    width: 100%;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.05);
    position: relative;
    top: -7px;
}

@media screen and (max-width: 991px) {
    .opening-hours-box {
        margin-bottom: 90px;
    }
}


/* ======= Promo Video style ======= */


/* play button */

.vid-area h5 {
    font-size: 27px;
    font-weight: 400;
    color: #f4f4f4;
    margin-bottom: 30px;
}

.play-button {
    position: relative;
    margin: auto;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 4;
}

.play-button svg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.circle-fill circle {
    opacity: 1;
    fill: rgba(174, 140, 100, 0.7);
}

.polygon {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 90px;
    font-size: 35px;
    z-index: 2;
}

.play-button svg.circle-fill {
    z-index: 1;
    stroke-dasharray: 322;
    stroke-dashoffset: 322;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.play-button:hover svg.circle-fill {
    stroke-dashoffset: 38.5;
}

.play-button svg.circle-track {
    z-index: 0;
}

.play-button.color {
    color: var(--main-color-1);
}

.play-button.color .circle-fill circle {
    fill: var(--main-color-1);
}


/* video section */

.video-wrapper {
    background-position: center;
}

.video-wrapper .vid {
    position: relative;
    z-index: 8;
}

.video-wrapper .vid .vid-butn:hover .icon {
    color: var(--main-color-2);
    border: 2px solid var(--main-color-2);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.video-wrapper .vid .vid-butn .icon {
    color: #f4f4f4;
    width: 90px;
    height: 90px;
    border: 2px solid #f4f4f4;
    border-radius: 50%;
    line-height: 90px;
    text-align: center;
    font-size: 40px;
    position: relative;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}


/* video gallery */

.video-gallery-button {
    position: relative;
    margin: auto;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 4;
    margin-left: 15px;
    float: right;
}

.video-gallery-polygon {
    z-index: 2;
    padding-left: 5px;
    display: inline-block;
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: var(--main-color-2);
    border-radius: 50%;
    border: 1px solid var(--main-color-2);
    color: #f4f4f4;
    padding: 12px 11px 12px 13px;
    line-height: 0;
}

.video-gallery-polygon:hover {
    background: var(--main-color-1);
    border: 1px solid var(--main-color-1);
    color: #f4f4f4;
}


/* ======= Booking Form style ======= */

.booking-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 5%;
    z-index: 5;
}

.booking2-wrapper {
    position: relative;
    margin-bottom: 40px;
}

.booking-inner {
    position: relative;
    border-radius: 0;
}

.booking-inner p small {
    font-size: 12px;
    color: #625c56;
}

.booking-inner p small b {
    font-size: 15px;
    color: var(--main-color-2);
}

.form1 {
    display: block;
    padding: 0;
    position: relative;
}

.form1 label {
    display: none;
}

.form1 .col1 {
    float: left;
    width: 20%;
}

.form1 .col2 {
    float: left;
    width: 14%;
}

.form1 .col3 {
    float: left;
    width: 18%;
}

.form1 .c1 {
    border-right: 1px solid #f1eeeb;
    height: 52.5px;
    margin-bottom: 15px;
}

.form1 .c2 {
    border-right: 1px solid #f1eeeb;
    height: 52.5px;
    margin-bottom: 15px;
}

.form1 .c3 {
    border-right: 1px solid #f1eeeb;
    height: 52.5px;
    margin-bottom: 15px;
}

.form1 .c4 {
    border-right: 1px solid #f1eeeb;
    height: 52.5px;
    margin-bottom: 15px;
}

.form1 .c5 {
    border-right: 1px solid #f1eeeb;
    height: 52.5px;
    margin-bottom: 15px;
}

.ui-datepicker .ui-datepicker-header {
    background: var(--main-color-2);
    border: var(--main-color-2);
    color: #f4f4f4;
}

.ui-datepicker {
    font-size: 15px;
    color: var(--main-color-1);
    background: #f4f4f4;
    z-index: 100 !important;
    border: 1px solid #f4f4f4;
    border-radius: 0px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    border: 1px solid #f8f5f0;
    background: #f8f5f0;
    color: var(--main-color-1);
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    border: 1px solid var(--main-color-2);
    background: var(--main-color-2);
    font-weight: normal;
    color: #f4f4f4;
}

.ui-datepicker .ui-datepicker-prev:after {
    font-size: 10px;
    content: "\e64a";
    font-family: "themify";
    color: #f4f4f4;
}

.ui-datepicker .ui-datepicker-next:before {
    font-size: 10px;
    content: "\e649";
    font-family: "themify";
    color: #f4f4f4;
    float: right;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
    background: transparent;
    border: 1px solid transparent;
}

.btn-form1-submit {
    display: block;
    padding: 0;
    border: none;
    margin: 0;
    width: 100%;
    background: var(--main-color-2);
    color: #f4f4f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    text-decoration: none;
    line-height: 52px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-out;
    font-family: 'Outfit', sans-serif;
}

.btn-form1-submit:hover {
    background: var(--main-color-1);
}

.btn-form1-submit:focus {
    outline: none;
}

.btn-form1-submit:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    background: inherit;
}

.btn-form2-submit {
    display: block;
    padding: 0;
    border: none;
    margin: 0;
    width: 100%;
    background: var(--main-color-2);
    color: #f4f4f4;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    text-decoration: none;
    line-height: 52px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-out;
    font-family: 'Outfit', sans-serif;
}

.btn-form2-submit:hover {
    background: #f4f4f4;
    color: var(--main-color-1);
}

.btn-form2-submit:focus {
    outline: none;
}

.btn-form2-submit:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    background: inherit;
}

.input1_inner {
    position: relative;
    position: relative;
}

.input1_inner:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    pointer-events: none;
    content: "\e6b6";
    font-family: "themify";
    font-size: 15px;
    color: var(--main-color-1);
    line-height: 1;
}

.input1_inner input {
    display: block;
    width: 100%;
    font-size: 15px;
    color: var(--main-color-1);
    padding: 15px 50px 15px 15px;
    background: #f4f4f4;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.input1_inner input::placeholder {
    font-size: 15px;
    color: var(--main-color-1) !important;
}

.input1_inner input:focus {
    outline: none;
    box-shadow: none;
    background: #f4f4f4;
    color: var(--main-color-1) !important;
}

.input2_inner {
    position: relative;
    position: relative;
}

.input2_inner input {
    display: block;
    width: 100%;
    font-size: 15px;
    color: var(--main-color-1);
    padding: 15px 40px 15px 15px;
    background: #f4f4f4;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.input2_inner input::placeholder {
    font-size: 15px;
    color: var(--main-color-1) !important;
}

.input2_inner input:focus {
    outline: none;
    box-shadow: none;
    background: #f4f4f4;
    color: var(--main-color-1) !important;
}

.select1_wrapper {
    margin-bottom: 0;
}

.select1_inner {
    position: relative;
}

.select1_inner:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    pointer-events: none;
    content: "\e64b";
    font-family: "themify";
    font-size: 10px;
    color: var(--main-color-1);
    font-weight: normal;
    line-height: 15px;
}

.select2 {
    width: 100%;
    background: #f4f4f4;
    margin-bottom: 15px;
}

.select2 *:focus {
    outline: none !important;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 500px;
}

.select2-container--default .select2-selection--single {
    background: none;
    border-radius: 0;
    border: none;
    height: auto;
}

.selection {
    display: inline-flex;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 15px;
    padding: 12.25px 49px 12.25px 15px;
    color: var(--main-color-1);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-dropdown {
    background: none;
    border-radius: 0;
    z-index: 100;
    min-width: 0px;
    border: none;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: 2px solid var(--main-color-1);
}

.select2-container--open .select2-dropdown--below {
    border-top: 2px solid var(--main-color-1);
}

.select2-results__option {
    padding: 10px 30px;
    background: #f4f4f4;
    font-size: 15px;
    line-height: 20px;
    color: var(--main-color-1);
    border-top: 1px solid var(--main-color-4);
    text-align: center;
}

.select2-results__option:first-child {
    border-top: none;
}

.small .select2-results__option {
    padding-left: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background: var(--main-color-2);
    color: #f4f4f4;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: var(--main-color-2);
    color: #f4f4f4;
}


/* responsive */

@media (max-width: 1259.98px) {
    .booking2-wrapper {
        margin-bottom: 30px;
    }
}

@media (max-width: 991.98px) {
    .booking2-wrapper {
        margin-bottom: 40px;
    }

    .form1 .col1 {
        width: 50%;
        border-bottom: 1px solid var(--main-color-4);
    }

    .form1 .c2 {
        border-right: none;
    }

    .form1 .col2 {
        width: 25%;
    }

    .form1 .col3 {
        width: 25%;
    }
}

@media (max-width: 767.98px) {
    .form1 .col1 {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid var(--main-color-4);
    }

    .form1 .col2 {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid var(--main-color-4);
    }

    .form1 .col3 {
        width: 100%;
        border-right: 0;
    }

    .btn-form1-submit:after {
        background: none;
    }

    .booking-wrapper {
        position: relative;
        bottom: auto;
        padding: 30px 0 0;
    }
}


/* booking form */

.booking-box {
    position: relative;
    background: var(--main-color-4);
    padding: 45px 30px 60px 30px;
    margin-bottom: -75px;
}

.booking-box .head-box h6 {
    font-size: 12px;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    color: var(--main-color-2);
    text-transform: uppercase;
    position: relative;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.booking-box .head-box h4 {
    font-size: 27px;
    color: var(--main-color-1);
    margin-bottom: 30px;
}

.booking-box .head-box h4 span {
    color: var(--main-color-1);
}

@media screen and (max-width: 991px) {
    .booking-box {
        margin-bottom: 90px;
        padding: 35px 20px;
    }
}


/* reservation button */

.reservations {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.reservations .icon {
    position: relative;
    top: -3px;
}

.reservations .icon span {
    font-size: 60px;
    color: var(--main-color-2);
}

.reservations .text {
    margin-left: 15px;
}

.reservations .text p {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 5px;
    color: var(--main-color-2);
    font-family: 'Outfit', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.reservations .text a {
    font-family: 'Outfit', sans-serif;
    font-size: 27px;
    color: var(--main-color-1);
    font-weight: 800;
}

.reservations .text a.color-1 {
    color: #f4f4f4;
}


/* ======= Pricing Tabs style  ======= */

.tabs .tab-links ul li {
    display: inline-block;
}

.tabs .tab-links .item-link {
    padding-right: 20px;
    position: relative;
    cursor: pointer;
    margin: 0 10px;
    color: var(--main-color-1);
    font-weight: 800;
    font-size: 24px;
}

.tabs .tab-links .item-link:after {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    width: 1px;
    height: 15px;
    background: rgba(145, 118, 90, 0.3);
}

.tabs .tab-links .item-link:last-of-type {
    padding-right: 0;
}

.tabs .tab-links .item-link:last-of-type:after {
    display: none;
}

.tabs .tab-links .item-link.current {
    pointer-events: none;
    color: var(--main-color-2);
}

.tabs .tab-content {
    display: none;
}

.tabs .tab-content.current {
    display: block;
}


/* ======= Pricing style ======= */

.barber-pricing {
    outline: none;
    outline: 0;
    border: none;
    box-shadow: none;
}

.barber-pricing .menu-list .item {
    line-height: 35px;
}

.barber-pricing .menu-list .flex .title {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: var(--main-color-1);
    font-family: 'Outfit', sans-serif;
    font-size: 17px;
    font-weight: 800;
}

.barber-pricing .menu-list .flex .title span {
    font-family: 'Outfit', sans-serif;
    color: #625c56;
}

.barber-pricing .menu-list .flex .title.white {
    color: #f4f4f4;
}

.barber-pricing .menu-list .flex .dots {
    width: 100%;
    position: relative;
    margin: 0 20px;
}

.barber-pricing .menu-list .flex .dots:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed rgba(145, 118, 90, 0.3);
}

.barber-pricing .menu-list .flex .price {
    margin-left: auto;
    font-weight: 800;
    color: var(--main-color-2);
    font-size: 17px;
}

.barber-pricing .menu-list .img {
    display: block;
    float: left;
    position: relative;
    top: 0px;
    margin-right: 15px;
    border-radius: 50%;
}

.barber-pricing .menu-list .img img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
}


/* ======= Pricing 2 style ======= */

.barber-pricing2 .img {
    position: relative;
    overflow: hidden;
}

.barber-pricing2 .img:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.barber-pricing2 .img img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.barber-pricing2 .content {
    width: 100%;
    padding: 30px;
}

@media screen and (max-width: 991px) {
    .barber-pricing2 .content .cont h4 {
        line-height: 1.25em;
    }

    .order2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }

    .order1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }
}

@media screen and (max-width: 480px) {
    .barber-pricing2 .content .date {
        width: 60px;
    }

    .barber-pricing2 .content .info .tags {
        margin: 5px 0 0;
    }

    .barber-pricing2 .content .date h3 {
        font-size: 40px;
    }
}


/* ======= Pricing 3 style ======= */

.barber-pricing3 .item {
    margin-bottom: 0px
}

.barber-pricing3 .item h2 {
    font-size: 48px;
    line-height: 48px;
    color: #f4f4f4;
    margin-bottom: 0px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* ======= Accordion Box (for Faqs) style ======= */

.accordion-box {
    position: relative;
    padding: 0;
}

.accordion-box .block {
    position: relative;
    background: transparent;
    border-radius: 0px;
    overflow: hidden;
    margin-bottom: 15px;
    border: 1px solid rgba(145, 118, 90, 0.3);
}

.accordion-box .block.active-block {
    background-color: transparent;
}

.accordion-box .block:last-child {
    margin-bottom: 0;
}

.accordion-box .block .acc-btn {
    position: relative;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    line-height: 30px;
    font-weight: 800;
    cursor: pointer;
    padding: 20px 30px;
    padding-left: 60px;
    color: var(--main-color-1);
    transition: all 500ms ease;
}

.accordion-box .block .acc-btn.size-20 {
    font-size: 18px;
}

.accordion-box .block .acc-btn .count {
    color: var(--main-color-2);
    padding-right: 3px;
}

.accordion-box .block .acc-btn:before {
    position: absolute;
    left: 35px;
    top: 22px;
    height: 30px;
    font-size: 12px;
    font-weight: normal;
    color: var(--main-color-2);
    line-height: 30px;
    content: "\e64b";
    font-family: "Themify";
    transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active {
    background-color: var(--main-color-2);
    color: #f4f4f4;
}

.accordion-box .block .acc-btn.active:before {
    font-family: 'Themify';
    content: "\e648";
    color: #f4f4f4;
}

.accordion-box .block .acc-content {
    position: relative;
    display: none;
}

.accordion-box .block .content {
    position: relative;
    padding: 30px 20px 20px 20px;
    background-color: transparent;
    color: #625c56;
}

.accordion-box .block .acc-content.current {
    display: block;
}

.accordion-box .block .content .text {
    display: block;
    position: relative;
    top: 0px;
    display: block;
}


/* ======= News & Blog 1 style ======= */

.news,
.blog {
    position: relative;
}

.news:before {
    top: auto;
    bottom: 0;
    height: 40%;
    background: var(--main-color-4);
}

.news:after,
.team:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 60%;
    width: 100%;
    background: var(--main-color-1);
    z-index: -1;
}

.news .item,
.blog .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}

.news .item:hover img,
.blog .item:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09);
    -webkit-filter: brightness(75%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.news .item:hover .con,
.blog .item:hover .con {
    bottom: 0;
}

.news .item img,
.blog .item img {
    -webkit-transition: all .5s;
    transition: all .5s;
    -webkit-filter: brightness(100%);
}

.news .item .date,
.blog .item .date {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 30px;
    background: var(--main-color-2);
    border: 1px solid var(--main-color-2);
    color: #f4f4f4;
    padding: 10px 10px 5px 10px;
    text-align: center;
}

.news .item .date span,
.blog .item .date span {
    font-family: 'Outfit', sans-serif;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    color: #f4f4f4;
    line-height: 1.2em;
}

.news .item .date i,
.blog .item .date i {
    font-family: 'Outfit', sans-serif;
    display: block;
    font-size: 24px;
    font-style: normal;
    color: #f4f4f4;
    font-weight: 800;
}

.news .item .con,
.blog .item .con {
    margin: -185px 30px 90px;
    padding: 30px;
    background-color: #f4f4f4 !important;
    border: 1px solid #f4f4f4;
    border-radius: 0;
    overflow: hidden;
    -webkit-transition: .2s cubic-bezier(.3, .58, .55, 1);
    transition: .2s cubic-bezier(.3, .58, .55, 1);
    bottom: -90px;
    position: relative;
}

.news .item .con .category,
.blog .item .con .category {
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 5px;
}

.news .item .con .category a,
.blog .item .con .category a {
    margin-right: 5px;
    position: relative;
    color: var(--main-color-2);
}

.news .item .con .category a:hover,
.blog .item .con .category a:hover {
    color: var(--main-color-1);
}

.news .item .con .category a:last-child,
.blog .item .con .category a:last-child {
    margin-right: 0;
}

.news .item .con .category a:last-child:after,
.blog .item .con .category a:last-child:after {
    display: none;
}

.news .item .con h5,
.news .item .con h5 a,
.blog .item .con h5,
.blog .item .con h5 a {
    color: var(--main-color-1);
    font-size: 24px;
    margin-bottom: 0px;
    font-family: 'Outfit', sans-serif;
    line-height: 1.25em;
}

.news .item .con h5:hover,
.news .item .con h5 a:hover,
.blog .item .con h5:hover,
.blog .item .con h5 a:hover {
    color: var(--main-color-1);
}

.news .item .con p,
.blog .item .con p {
    color: #f4f4f4;
    font-size: 15px;
}

.news .item .con i,
.blog .item .con i {
    color: #f4f4f4;
    font-size: 20px;
}


/* ======= News & Blog 2 style ======= */

.news2 .item {
    margin-bottom: 30px;
}

.news2 .post-img {
    position: relative;
    overflow: hidden;
}

.news2 .post-img:hover img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.news2 .post-img a {
    display: block;
}

.news2 .post-img img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.news2 .post-img .date {
    display: inline-block;
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: var(--main-color-2);
    border: 1px solid var(--main-color-2);
    color: #f4f4f4;
    padding: 10px 10px 5px 10px;
    text-align: center;
}

.news2 .post-img .date span {
    font-family: 'Outfit', sans-serif;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1em;
    color: #f4f4f4;
    font-weight: 800;
    letter-spacing: 1px;
}

.news2 .post-img .date i {
    font-family: 'Outfit', sans-serif;
    display: block;
    font-size: 24px;
    font-style: normal;
    color: #f4f4f4;
    line-height: 1.4em;
    font-weight: 800;
}

.news2 .post-cont {
    padding: 30px 0;
}

.news2 .post-cont .tag {
    display: block;
    font-weight: 800;
    font-size: 12px;
    color: var(--main-color-2);
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.news2 .post-cont h5 {
    font-size: 36px;
    margin-bottom: 15px;
}

.news2 .post-cont h5 a {
    color: var(--main-color-1);
    font-size: 36px;
}

.news2 .post-cont h5 a:hover {
    color: var(--main-color-1);
}

.news2 .post-cont .date {
    font-family: 'Outfit', sans-serif;
    margin-top: 0px;
    font-size: 12px;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.news2 .post-cont .date a {
    color: var(--main-color-1);
}

.news2 .post-cont i {
    color: var(--main-color-1);
    margin: 0 10px;
    font-size: 12px;
}


/* news sidebar */

.news2-sidebar .widget {
    background: #f4f4f4;
    padding: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}

.news2-sidebar .widget ul {
    margin-bottom: 0;
    padding: 0;
}

.news2-sidebar .widget ul li {
    margin-bottom: 15px;
    color: var(--main-color-1);
    font-size: 15px;
    line-height: 1.5em;
}

.news2-sidebar .widget ul li a {
    color: var(--main-color-1);
}

.news2-sidebar .widget ul li a.active {
    color: var(--main-color-1);
}

.news2-sidebar .widget ul li a:hover {
    color: var(--main-color-2);
}

.news2-sidebar .widget ul li:last-child {
    margin-bottom: 0;
}

.news2-sidebar .widget ul li i {
    font-size: 9px;
    margin-right: 10px;
}

.news2-sidebar .widget .recent li {
    display: block;
    overflow: hidden;
}

.news2-sidebar .widget .recent .thum {
    width: 90px;
    overflow: hidden;
    float: left;
}

.news2-sidebar .widget .recent a {
    display: block;
    margin-left: 105px;
}

.news2-sidebar .widget-title {
    margin-bottom: 15px;
}

.news2-sidebar .widget-title h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--main-color-4);
    font-size: 24px;
    line-height: 1.75em;
    margin-bottom: 15px;
    color: var(--main-color-1);
}

.news2-sidebar .search form {
    position: relative;
}

.news2-sidebar .search form input {
    width: 100%;
    padding: 10px;
    border: 0;
    background: var(--main-color-4);
    margin-bottom: 0;
}

.news2-sidebar .search form button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    color: var(--main-color-1);
    border: 0;
    padding: 10px;
    cursor: pointer;
}

.news2-sidebar .tags li {
    margin: 3px !important;
    padding: 8px 20px;
    background-color: var(--main-color-4);
    border: 1px solid var(--main-color-4);
    color: var(--main-color-1) !important;
    float: left;
}

.news2-sidebar ul.tags li a {
    font-size: 15px;
    color: var(--main-color-1);
}

.news2-sidebar ul.tags li:hover,
.news2-sidebar ul.tags li a:hover {
    background-color: var(--main-color-2);
    color: #f4f4f4;
}


/* pagination */

.news-pagination-wrap {
    padding: 0;
    margin: 0;
    text-align: center;
}

.news-pagination-wrap li {
    display: inline-block;
    margin: 0 5px;
}

.news-pagination-wrap li a {
    background: transparent;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--main-color-1);
    font-weight: 400;
    border: 1px solid rgba(145, 118, 90, 0.1);
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    border-radius: 100%;
}

.news-pagination-wrap li a i {
    font-size: 11px;
}

.news-pagination-wrap li a:hover {
    opacity: 1;
    text-decoration: none;
    background: transparent;
    border: 1px solid var(--main-color-2);
    color: var(--main-color-2);
}

.news-pagination-wrap li a.active {
    background-color: transparent;
    border: 1px solid var(--main-color-2);
    color: var(--main-color-2);
}

@media screen and (max-width: 768px) {
    .news-pagination-wrap {
        padding: 0;
        margin: 0;
        text-align: center;
    }
}


/* ======= Post style ======= */

.news-post-section {
    padding-top: 120px;
}

.post-comment-section {
    margin-top: 60px;
}

.post-comment-section h3 {
    font-size: 18px;
    color: var(--main-color-1);
    margin-bottom: 15px;
}

.post-comment-section h3 span {
    font-size: 12px;
    color: var(--main-color-2);
    margin-left: 15px;
    font-weight: 300;
}

.news-post-caption h1 {
    margin-bottom: 15px;
    font-size: 40px;
    text-transform: uppercase;
}

.news-post-comment-wrap {
    display: flex;
    margin-bottom: 60px;
}

.post-user-comment {
    margin-right: 20px;
    flex: 0 0 auto;
}

.post-user-comment img {
    border-radius: 100%;
    width: 100px;
}

.post-user-content {
    margin-right: 26px;
}

a.post-repay {
    font-size: 12px;
    color: var(--main-color-1);
    margin: 0;
    font-weight: 400;
}

a:hover.post-repay {
    color: var(--main-color-2);
}


/* ======= Contact style ======= */


/* maps */

.google-maps {
    width: 100%;
    height: 550px;
    display: block;
    border: none;
}

.map {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.contact-form {
    width: 100%;
    display: block;
    padding: 60px 30px 30px 30px;
    position: relative;
    z-index: 1;
}

.contact-form h4.white {
    color: #f4f4f4;
}


/* info box */

.info-box .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.info-box .item .icon {
    color: var(--main-color-1);
    margin-right: 15px;
    border: 1px solid rgba(145, 118, 90, 0.3);
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 15px;
    border-radius: 100%;
}

.info-box .item .icon:before {
    font-size: 27px;
    font-weight: 400;
}

.info-box .item h5 {
    color: var(--main-color-1);
    margin-bottom: 5px;
    font-size: 17px;
}

.info-box .item h6 {
    color: var(--main-color-2);
    margin-bottom: 0px;
    font-size: 22px;
}

.info-box .item p {
    font-size: 15px;
    margin-bottom: 0;
}


/* ====== Coming Soon ======= */

.comming {
    min-height: 100vh;
    height: auto;
    position: relative;
    background-blend-mode: overlay;
    background: rgba(0, 0, 0, .4);
    background-image: url("https://duruthemes.com/demo/html/perukar/multipage-image/img/slider/11.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #f4f4f4;
}

@media (max-width: 767px) {
    .comming {
        min-height: 120vh;
    }
}

.comming .item {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 15px;
    margin-bottom: 30px;
}

.comming h6 {
    color: var(--main-color-2);
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 2px;
}

.comming h3 {
    font-size: 48px;
    color: #f4f4f4;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0px;
}

.comming h2 {
    font-size: 48px;
    color: #f4f4f4;
    position: relative;
    margin-bottom: 5px;
}

.comming h1 {
    font-size: 180px;
    margin-bottom: 0px;
    position: relative;
    color: var(--main-color-2);
    line-height: 1em;
}

.comming p {
    color: #f4f4f4;
    font-size: 15px;
}


/* coming form */

.comming form {
    margin-top: 30px;
    position: relative;
}

.comming form input {
    width: 100%;
    padding: 11px 50px 11px 11px;
    background-color: transparent;
    border: 1px solid var(--main-color-2);
    border-radius: 0px;
    color: #f4f4f4;
    margin-bottom: 15px;
    outline: none;
}

.comming form input::placeholder {
    color: #f4f4f4;
}

.comming form button {
    background-color: var(--main-color-2);
    padding: 9px 25px;
    border-radius: 0px;
    color: #f4f4f4;
    border: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 800;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.comming form button:hover {
    background-color: #f4f4f4;
    color: var(--main-color-1);
    outline: none;
}


/* go home */

.go-back {
    margin-top: 45px;
}

.go-back a {
    color: #f4f4f4;
    display: inline-block;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.go-back a:hover {
    color: var(--main-color-2);
}

.go-back a span {
    transition: all .5s;
}

.go-back a span i {
    color: #f4f4f4;
    font-size: 12px;
}

.go-back a:hover span {
    padding-right: 15px;
}


/* ======= 404 Page style ======= */

.error-form {
    position: relative;
    max-width: 520px;
    margin: 50px auto 0;
}

.error-form form {
    position: relative;
}

.error-form .form-group {
    position: relative;
    margin: 0;
}

.error-form .form-group input[type="text"],
.error-form .form-group input[type="search"],
.error-form .form-group input[type="email"] {
    position: relative;
    width: 100%;
    line-height: 30px;
    padding: 20px 60px 20px 30px;
    height: 70px;
    display: block;
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    background: #f4f4f4;
    color: var(--main-color-1);
    border-radius: 7px;
    border: 1px solid #f4f4f4;
    transition: all 300ms ease;
}

.error-form .form-group input[type="text"]:focus,
.error-form .form-group input[type="email"]:focus,
.error-form .form-group input[type="search"]:focus {
    border-color: #f4f4f4;
}

.error-form .form-group input::-webkit-input-placeholder {
    color: #f4f4f4;
}


/* ======= Buttons style ======= */


/* button 1 */

.button-1 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid #f4f4f4;
    border-radius: 0;
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: var(--main-color-4);
    line-height: 20px;
    font-family: 'Outfit', sans-serif;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-1:hover {
    border: 1px solid var(--main-color-2);
    background-color: var(--main-color-2);
    color: #f4f4f4;
}


/* button 2 */

.button-2 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid #f4f4f4;
    border-radius: 0;
    background-color: #f4f4f4;
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: var(--main-color-1);
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-2:hover {
    border: 1px solid var(--main-color-1);
    background-color: var(--main-color-1);
    color: #f4f4f4;
}


/* button 3 */

.button-3 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid var(--main-color-1);
    border-radius: 0;
    background-color: var(--main-color-1);
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: #f4f4f4;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-3:hover {
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    color: var(--main-color-1);
}


/* button 4 */

.button-4 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid var(--main-color-1);
    border-radius: 0;
    background-color: var(--main-color-1);
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: #f4f4f4;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-4:hover {
    border: 1px solid var(--main-color-2);
    background-color: var(--main-color-2);
    color: #f4f4f4;
}


/* button 5 */

.button-5 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid var(--main-color-2);
    border-radius: 0;
    background-color: var(--main-color-2);
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: #f4f4f4;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-5:hover {
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    color: var(--main-color-1);
}


/* button 5 */

.button-6 {
    display: inline-block;
    height: auto;
    padding: 15px 25px;
    border: 1px solid var(--main-color-2);
    border-radius: 0;
    background-color: var(--main-color-2);
    -webkit-transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    transition: border-color 400ms ease, color 400ms ease, background-color 400ms ease;
    color: #f4f4f4;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-6:hover {
    border: 1px solid var(--main-color-1);
    background-color: var(--main-color-1);
    color: #f4f4f4;
}


/* button link */

.link-btn {
    display: inline-block;
    position: relative;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
}

a.link-btn span {
    margin-right: 5px;
    font-size: 12px;
}

.link-btn.blck {
    color: var(--main-color-1);
}

.link-btn i {
    margin-left: 3px;
    font-size: 15px;
}

.link-btn:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--main-color-2);
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.link-btn:hover::before {
    width: 100%
}


/* ======= Footer style ======= */

.footer {
  position: relative;
  display: block;
  width: 100%;
}

.footer p {
    color: #aba59f;
}

.footer-top {
    position: relative;
    display: block;
    background-color: var(--main-color-1);
    padding: 90px 0 60px 0;
    z-index: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
}

.footer-top .footer-column {
    margin-bottom: 30px;
}

.footer-title {
    font-size: 27px;
    color: #f4f4f4;
    margin-bottom: 15px;
}

.footer-about-text {
    font-size: 15px;
    color: #aba59f;
    margin: 0;
}

.footer-about-social-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
}

.footer-about-social-list a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: var(--main-color-2);
    font-size: 15px;
    background-color: transparent;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-about-social-list a + a {
    margin-left: 15px;
}

.footer-about-social-list a:hover {
    background-color: transparent;
    color: #f4f4f4;
}

.footer-explore-list li + li {
    margin-top: 3px;
}

.footer-explore-list li a {
    position: relative;
    display: block;
    font-size: 15px;
    color: var(--main-color-1);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-explore-list li a:hover {
    padding-left: 15px;
    color: var(--main-color-1);
}

.footer-explore-list li a:before {
    position: absolute;
    top: 55%;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 6px;
    content: "";
    background-color: var(--main-color-1);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    border-radius: 50%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-explore-list li a:hover:before {
    opacity: 1;
}

.footer-contact {
    position: relative;
    display: block;
}

.footer-contact .footer-title {
    margin-bottom: 15px;
}

.footer-contact-text {
    font-size: 15px;
    margin: 0;
    color: #aba59f;
    padding-bottom: 15px;
}

.footer-contact-info p {
    margin: 0;
    color: #aba59f;
}

p.footer-contact-phone {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    color: var(--main-color-2);
    font-weight: 800;
    display: block;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-contact-phone:hover {
    color: #f4f4f4;
}

.footer-contact-mail {
    font-size: 15px;
    line-height: 3em;
    color: #aba59f;
    position: relative;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-contact-mail:hover {
    color: #f4f4f4;
}

.footer-contact-mail:before {
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--main-color-2);
    content: "";
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-contact-mail:hover:before {
    background-color: #aba59f;
}


/* language */

.footer-language {
    width: 160px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 30px;
}

.footer-language i {
    position: absolute;
    right: 30px;
    top: 17px;
    font-size: 15px;
    opacity: 0.6;
    color: rgba(255, 255, 255, 0.7);
}

.footer-language select {
    height: 52px;
    background: transparent;
    color: #aba59f;
    border: 1px solid var(--main-color-2);
    border-radius: 0px;
}

.footer-language select option {
    color: #f4f4f4;
}

select {
    width: 160px;
    max-width: 100%;
    height: 70px;
    padding: 0 30px;
    border: 1px solid #625c56;
    color: #aba59f;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.footer-bottom {
    position: relative;
    display: block;
    background-color: var(--main-color-1);
    -webkit-box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.04);
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.04);
    padding: 32px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
}

.footer-bottom-inner {
    position: relative;
    display: block;
}

.footer-bottom-copy-right {
    font-size: 12px;
    color: #aba59f;
    margin: 0;
}

.footer-bottom-copy-right a {
    color: #aba59f;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.footer-bottom-copy-right a:hover {
    color: var(--main-color-2);
}


/* subscribe */

.subscribe form {
    margin-top: 30px;
    position: relative;
}

.subscribe form input {
    width: 100%;
    padding: 16px 60px 17px 16px;
    background-color: transparent;
    border: 1px solid var(--main-color-2);
    border-radius: 0px;
    color: #aba59f;
    margin-bottom: 15px;
    outline: none;
}

.subscribe form input::placeholder {
    color: #aba59f;
}

.subscribe form button {
    background-color: var(--main-color-2);
    padding: 14px 20px;
    border-radius: 0px;
    color: #f4f4f4;
    border: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    outline: none;
}

.subscribe form button:hover {
    background-color: #f4f4f4;
    color: var(--main-color-1);
    outline: none;
}


/* footer list */

.footer .item ul {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.footer .item ul li .tit,
.footer .item ul li .dots,
.footer .item ul li span {
    display: table-cell;
    white-space: nowrap;
}

.footer .item ul li {
    margin-bottom: 15px;
    font-size: 15px;
}

.footer .item ul li:last-of-type {
    margin-bottom: 0;
}

.footer .item ul li span {
    white-space: nowrap;
    padding-left: 10px;
    font-family: 'Outfit', sans-serif;
    color: #aba59f;
}

.footer .item ul li .tit {
    padding-right: 10px;
    color: #aba59f;
}

.footer .item ul li .dots {
    height: 4px;
    width: 100%;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    position: relative;
    top: -7px;
}


/* ======= toTop Button style ======= */

.progress-wrap {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.progress-wrap::after {
    position: absolute;
    font-family: 'themify';
    content: '\e648';
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    font-weight: normal;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap svg path {
    fill: none;
}

.progress-wrap svg.progress-circle path {
    stroke-width: 4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear;
}

.progress-wrap {
    -webkit-box-shadow: inset 0 0 0 0px rgba(217, 214, 209, 0.5);
    box-shadow: inset 0 0 0 0px rgba(217, 214, 209, 0.5);
}

.progress-wrap::after {
    color: var(--main-color-2);
}

.progress-wrap svg.progress-circle path {
    stroke: var(--main-color-2);
}


/* =======  Overlay Effect Bg image style  ======= */

[data-overlay-dark],
[data-overlay-light] {
    position: relative;
}

[data-overlay-dark] .container,
[data-overlay-light] .container {
    position: relative;
    z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

[data-overlay-dark]:before {
    background: #000;
}

[data-overlay-light]:before {
    background: #f4f4f4;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span {
    color: #f4f4f4;
}

[data-overlay-light] h1,
[data-overlay-light] h2,
[data-overlay-light] h3,
[data-overlay-light] h4,
[data-overlay-light] h5,
[data-overlay-light] h6,
[data-overlay-light] span {
    color: #625c56;
}

[data-overlay-dark] p {
    color: #f4f4f4;
}

[data-overlay-light] p {
    color: #625c56;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
    opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
    opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
    opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
    opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
    opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
    opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
    opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
    opacity: 0.7;
}

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
    opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before {
    opacity: 0.9;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
    opacity: 1;
}


/* ======= Responsive style ======= */

@media (min-width: 1200px) {
    .container {
        max-width: 1140px !important;
    }
}

@media screen and (max-width: 991px) {
    .bg-fixed {
        background-attachment: scroll !important;
    }
    .header {
        background-attachment: scroll !important;
        background-position: 50% 0% !important;
    }
    .header .caption h1 {
        font-size: 40px;
    }
    .video-fullscreen-wrap .booking-box,
    .kenburns-inner .booking-box,
    .banner-header .booking-box {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .header .caption h1 {
        font-size: 35px;
        margin-bottom: 15px;
    }

    .header .caption h1 span {
        font-size: 20px;
    }

    .header .caption h4 {
        font-size: 15px;
    }

    .header .caption p {
        font-size: 15px;
        margin-right: 10px;
    }
    
    .banner-header h2 {
        font-size: 36px;
        letter-spacing: 0;
    }
    
    .section-head .section-title {
        font-size: 27px;
        line-height: 27px;
    }

    .portfolio .filtering span {
        margin-bottom: 10px;
    }

    .news .item .con h5,
    .news .item .con h5 a {
        font-size: 24px;
    }
    
    .news2 .post-cont h5 {
    font-size: 24px;
    margin-bottom: 15px;
        line-height: 30px;
    }

    .news2 .post-cont h5 a {
        color: var(--main-color-1);
        font-size: 24px;
        line-height: 30px;
    }

    .post-comment-section h3 span {
        margin-left: 0px;
    }

    .section-head .section-backtitle {
        display: none;
    }

    .clients {
        padding-bottom: 20px;
    }

    .barber-services-2 .caption h4,
    .barber-services-2 .caption h4 a {
        font-size: 24px;
    }

    .news2 .post-cont h5 {
        font-size: 20px;
    }

    .video-fullscreen-wrap .caption.text-left,
    .video-fullscreen-wrap .caption,
    .banner-header .caption {
        text-align: center;
    }

    .video-fullscreen-wrap h3 {
        font-size: 36px;
    }

    .video-fullscreen-wrap h3 span {
        font-size: 27px;
    }

    .booking-wrapper {
        background: var(--main-color-4);
        padding-bottom: 30px;
    }

    .barber-pricing .menu-list .flex .dots {
        margin: 0 5px;
    }

    .barber-pricing3 .item h2 {
        font-size: 35px;
    }

    .comming h1 {
        font-size: 100px;
    }

    .comming h2 {
        font-size: 25px;
    }

    .comming h3 {
        font-size: 35px;
    }

    .info-box .item h5 {
        font-size: 15px;
    }
}


#messages-list {
  flex: 1;
  padding: 20px;
  list-style-type: none;
  overflow-y: scroll;
}

#messages-list li {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  clear: both;
}

#messages-list li.user {
  background-color: var(--main-color-4);

  text-align: right;
  float: right;
}

#messages-list li.bot {
  /* background-color: var(--main-color-1); */
  color: var(--main-color-2);

  text-align: left;
  float: left;
}

.custom-calendar-bot{
  background-color: var(--main-color-1) !important;
  width: 100%; /* Garantir que ocupe 100% da largura disponível */
  max-width: 100%; /* Não ultrapassar a largura do contêiner */
  overflow: hidden; /* Evitar que o conteúdo ultrapasse a área visível */
  box-sizing: border-box; /* Para incluir o padding e a borda no tamanho total */
}

.disabled-element {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%);
}

.custom-navbar {
  background: linear-gradient(45deg, var(--main-color-1), var(--main-color-2)); /* Gradiente de azul */
  transition: background 0.3s ease-in-out;
}

.navbar-brand {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--main-color-1);
}

.navbar-text {
  color: var(--main-color-1);
  font-size: 1rem;
}

.navbar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}