@media only screen  and (max-device-width: 767px){
    .align-title{
        top: 60%;
    }
}

@media only screen  and (min-device-width: 1024px){
    .align-title{
        top: 50%;
    }
}


.align-title{
    position: absolute;
    width: 100%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
};